import React from "react";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { Brightness2, WbSunny } from "@material-ui/icons";
import { useThemeSelector } from "theme";
import { useTranslation } from "i18n";

const ChangeThemeButton = () => {
  const toggleTheme = useThemeSelector();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Tooltip title={t("CHANGE_THEME")}>
      <IconButton onClick={toggleTheme}>
        {
          theme.palette.type === "light"
            ? <Brightness2 />
            : <WbSunny />
        }
      </IconButton>
    </Tooltip>
  );
};

export default ChangeThemeButton;
