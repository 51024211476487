import MenuItem from "@material-ui/core/MenuItem";
import EditUserDialog from "components/Dialogs/EditUserDialog";
import Popoverize from "components/Popoverize";
import { useTranslation } from "i18n";
import { useDialogs } from "store/Dialogs";
import useHit from "hooks/useHit";
import { useSession } from "store/Session";
import Link from "next/link";
import routes from "routes";

const ProfilePopover = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const session = useSession();
  const hit = useHit();
  const isDev = process.env.NEXT_PUBLIC_ENABLE_DEV_TOOLS === "yes, enable dev tools";

  const signOut = async () => {
    const shouldCloseSession = await dialogs.confirm({
      title: t("ARE_YOU_SURE_SIGN_OUT"),
      maxWidth: "xs",
      fullScreen: false,
    });
    if (shouldCloseSession) {
      localStorage.removeItem("session");
      session.refresh();
    }
  };

  const onDelete = async () => {
    const shouldDelete = await dialogs.confirm({
      title: "Borrar cuenta?",
      maxWidth: "xs",
      fullScreen: false,
      continueLabel: "Borrar",
    });
    if (shouldDelete) {
      const { error } = await hit();
      if (!error) {
        localStorage.removeItem("session");
        window.location.reload();
      }
    }
  };

  const onEditUser = () => {
    dialogs.create({
      DialogType: EditUserDialog,
    });
  };

  return (
    <Popoverize
      {...rest}
      options={(
        <>
          {
            isDev && (
              <MenuItem onClick={onDelete}>
                Eliminar cuenta
              </MenuItem>
            )
          }
          <MenuItem onClick={onEditUser}>
            {t("EDIT_PROFILE")}
          </MenuItem>
          <Link href={routes.MY_POSTS} passHref>
            <MenuItem>
              {t("MY_POSTS")}
            </MenuItem>
          </Link>
          <MenuItem onClick={signOut}>
            {t("SIGNOUT")}
          </MenuItem>
          {/* <ListItem
            dense
            style={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Typography
              variant="caption"
              style={{
                fontSize: "0.6rem",
              }}
            >
              {`Version ${process.env.NEXT_PUBLIC_NPM_VERSION}`}
            </Typography>
          </ListItem> */}
        </>
      )}
    >
      {children}
    </Popoverize>
  );
};

export default ProfilePopover;
