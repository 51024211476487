import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import RulesAndGuidelinesDialog from "components/Dialogs/RulesAndGuidelinesDialog";
import ProfilePopover from "components/MagicButtons/ProfilePopopver";
import Link from "next/link";
import routes from "routes";
import { useSession } from "store/Session";
import { useDialogs } from "store/Dialogs";
import { Avatar, Badge, Hidden, Tooltip, useTheme, withStyles } from "@material-ui/core";
import HorizontalArragement from "components/HorizontalArragement";
import InfoIcon from "@material-ui/icons/Info";
// import ChangeThemeButton from "components/MagicButtons/ChangeThemeButton";
import { useTranslation } from "i18n";
import RatherMember from "components/Icons/RatherMember";
import RatherCoin from "components/Icons/RatherCoin";
import WikiLogoLight from "components/Logos/WikiLogoLight";
import ChangeThemeButton from "components/MagicButtons/ChangeThemeButton";
import WikiLogoDark from "components/Logos/WikiLogoDark";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
    paddingTop: "5%",
  },
  header: {
    width: "100%",
    minHeight: 70,
    display: "flex",
    alignItems: "center",
    position: "fixed",
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
  },
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
  },
  links: {
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
  balance: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 28,
    border: `2px solid ${theme.palette.primary}`,
    padding: "0 4px",
  },
}))(Badge);

const Desktop = ({ children }) => {
  const classes = useStyles();
  const session = useSession();
  const theme = useTheme();
  const dialogs = useDialogs();
  const { t } = useTranslation();

  const onRules = () => dialogs.create({
    DialogType: RulesAndGuidelinesDialog,
  });

  const userBalance = (session.data?.user?.postsCount || 0) * 10;

  return (
    <Box className={classes.container}>
      <Box className={classes.header} elevation={0}>
        <Container maxWidth="lg" className={classes.headerContainer}>
          <Link passHref href={routes.INDEX}>
            <Button>
              {
                theme.palette.type === "light"
                  ? <WikiLogoLight />
                  : <WikiLogoDark />
              }
            </Button>
          </Link>
          <Hidden smDown>
            <Box className={classes.links}>
              <Button onClick={onRules}>
                <Typography variant="body2">
                  {t("CODE_OF_CONDUCT")}
                </Typography>
              </Button>
              <Button onClick={() => {
                const dialog = dialogs.create({
                  title: t("ABOUT_POPUP_TITLE"),
                  subtitle: t("ABOUT_POPUP_TEXT"),
                  actions: [{ text: "Ok", onClick: () => dialog.close() }],
                });
              }}
              >
                <Typography variant="body2">
                  {t("ABOUT")}
                </Typography>
              </Button>
              <Button onClick={() => window.open("https://ratherlabs.com", "_blank")}>
                <Typography variant="body2">
                  Rather Labs
                </Typography>
              </Button>
            </Box>
          </Hidden>
          <Box flexGrow={1} />
          <HorizontalArragement>
            <ChangeThemeButton />
            {session.data
              ? (
                <HorizontalArragement>
                  <Typography variant="h6">
                    {userBalance}
                  </Typography>
                  <StyledBadge
                    badgeContent={(
                      <Tooltip color={theme.palette.type === "light" ? "primary" : "white"} title={<Typography variant="caption" color="white">{t("RATHER_COINS")}</Typography>} arrow>
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    )}
                    className={classes.balance}
                  >
                    <RatherCoin />
                  </StyledBadge>
                  <ProfilePopover>
                    <Button>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        badgeContent={
                          session.data.user.israthermember && (
                            <Box>
                              <RatherMember fontSize="medium" />
                            </Box>
                          )
                        }
                      >
                        <Avatar src={session.data.user.avatar} />
                      </Badge>
                    </Button>
                  </ProfilePopover>
                </HorizontalArragement>
              )
              : (
                <Link passHref href={routes.SIGN_IN}>
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    {t("CALL_TO_ACTION_HEADER")}
                  </Button>
                </Link>
              )}
          </HorizontalArragement>
        </Container>
      </Box>
      <Box className={classes.contentContainer}>
        {children}
      </Box>
    </Box>
  );
};

export default Desktop;
