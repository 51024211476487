import React from "react";

const WikiLogoDark = ({ ...props }) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 434.7 109.74"
    style={{
      enableBackground: "new 0 0 434.7 109.74",
    }}
    xmlSpace="preserve"
    width="10em"
    height="3em"
    {...props}
  >
    <style>{".st1{fill:#fff}"}</style>
    <path
      d="m46.12 62.82 6.84 6.85h6.57l-6.72-6.62h.02l-.29-.29c4.61-.72 6.63-4.33 6.63-7.89-.01-4.21-2.09-8.11-8.44-8.11H36.26v6.18l5.86 5.87H36.25l-.01 4.09h.01l6.63 6.77h5.84l-6.39-6.41h.01l-.41-.41 4.19-.03zm-5.71-11.97h10.06c2.29 0 4.76.8 4.76 4.02 0 3.11-1.82 3.94-5.19 3.94H48.5l-8.09-7.96z"
      style={{
        fill: "#fff",
        enableBackground: "new",
      }}
    />
    <path
      className="st1"
      d="M148.97 72.26V43.25h12.44c3.23 0 5.8.86 7.71 2.57s2.86 3.9 2.86 6.55c0 2.18-.67 4.05-2.01 5.6s-3.23 2.58-5.66 3.11l7.46 11.19h-4.35l-7.05-10.78h-7.67v10.78l-3.73-.01zm3.73-14.3h8.71c2.24 0 3.94-.5 5.1-1.51s1.74-2.37 1.74-4.08-.58-3.07-1.74-4.08c-1.16-1.01-2.86-1.51-5.1-1.51h-8.71v11.18zM175.79 72.26l7.46-29.02h11.4l7.46 29.02h-3.73l-2.28-8.5h-14.3l-2.28 8.5h-3.73zm6.84-12.02h12.64l-3.52-13.47h-5.6l-3.52 13.47zM202.61 46.77v-3.52h23.63v3.52h-9.95v25.5h-3.73v-25.5h-9.95zM230.88 72.26V43.25h3.73v12.64h15.55V43.25h3.73v29.02h-3.73V59.41h-15.55v12.85h-3.73zM261.43 72.26V43.25h20.31v3.52h-16.58v9.12h13.47v3.52h-13.47v9.33h16.58v3.52h-20.31zM287.63 72.26V43.25h12.44c3.23 0 5.8.86 7.71 2.57s2.86 3.9 2.86 6.55c0 2.18-.67 4.05-2.01 5.6s-3.23 2.58-5.66 3.11l7.46 11.19h-4.35l-7.05-10.78h-7.67v10.78l-3.73-.01zm3.73-14.3h8.71c2.24 0 3.94-.5 5.1-1.51s1.74-2.37 1.74-4.08-.58-3.07-1.74-4.08c-1.16-1.01-2.86-1.51-5.1-1.51h-8.71v11.18zM314.25 43.25h7.67l3.73 20.31h.41l3.9-20.31h9.2l3.9 20.31h.42l3.73-20.31h7.67l-6.01 29.02H338.5l-3.73-19.48h-.41l-3.73 19.48h-10.36l-6.02-29.02zM359.1 72.26V43.25h7.67v29.02h-7.67v-.01zM373.07 72.26V43.25h7.67v10.78h3.32l7.05-10.78h8.71l-9.12 13.68 9.74 15.34h-8.71l-7.25-11.19h-3.73v11.19h-7.68v-.01zM404.04 72.26V43.25h7.67v29.02h-7.67v-.01zM81.26 46.88l5.62-.73 2.93 15.97h.31l3.21-16.71 6.18-.82 3.36 17.53h.31l3.42-18.63 6.01-.87-4.98 24.02-8.37 1.08-2.62-13.69h-.31l-2.8 14.62-7.78 1.02-4.49-22.79z"
    />
    <path
      className="st1"
      d="m121.46 26.48-49.34 6.78H22.99v49.92h48.55v.09l.66-.09h57.81v-50.2l-8.55-6.5zM71.54 81.93H24.23V34.49h47.31v47.44zm1.37-47.54 47.31-6.5v47.42l-47.31 6.5V34.39zm8.3 47.55 39.46-5.42 6.93 5.42H81.21zm47.56-.65-7.31-5.72V28.02l7.31 5.55v47.72z"
    />
  </svg>
);

export default WikiLogoDark;
