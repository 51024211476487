module.exports = {
  CALLBACK_FACEBOOK: "/cb/facebook",
  CALLBACK_GOOGLE: "/cb/google",
  INDEX: "/",
  POST: (id) => `/post/${id}`,
  PROFILE: (id) => `/profile/${id}`,
  EDIT_POST: (id) => `/post/edit/${id}`,
  MY_POSTS: "/my-posts",
  CREATE_POST: "/create-post",
  SIGN_IN: "/sign-in",
  WHY_THO: "/why-tho",
};
