import SvgIcon from "@material-ui/core/SvgIcon";

const RatherMember = ({ size = 1, ...props }) => (
  <SvgIcon
    width={47.658 * size}
    height={57.27 * size}
    viewBox="0 0 57.658 67.27"
    {...props}
  >
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 600 600"
      style={{
        enableBackground: "new 0 0 600 600",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>{".st1{fill:#fff}"}</style>
      <circle
        cx={300}
        cy={300}
        r={298.71}
        style={{
          fill: "#151515",
        }}
      />
      <path
        className="st1"
        d="M334.91 159.09H156.96v50.31h174.79c28.22 0 58.51 9.89 58.51 49.45 0 38.22-22.38 48.47-63.86 48.47h-19.58v50.31h28.95c72.01 0 102.9-49.82 102.9-98.78-.01-51.77-25.55-99.76-103.76-99.76z"
      />
      <path
        className="st1"
        d="M156.96 159.09v76.07L362.4 440.91h80.77L156.96 159.09z"
      />
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={-2353.914}
        y1={7540.018}
        x2={-2297.84}
        y2={7540.018}
        gradientTransform="matrix(1 0 0 -1 2510.87 7724.265)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0b0d0d",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#020203",
          }}
        />
      </linearGradient>
      <path
        d="m156.96 159.09 51.09 50.31h4.99l-56.08-50.31z"
        style={{
          opacity: 0.15,
          fill: "url(#SVGID_1_)",
          enableBackground: "new",
        }}
      />
      <path
        className="st1"
        d="M310.22 440.9h-71.89l-81.5-83.27.12-50.31h20.19L310.22 440.9z"
      />
      <linearGradient
        id="SVGID_00000126322287376476644270000007800529574446808487_"
        gradientUnits="userSpaceOnUse"
        x1={-2281.906}
        y1={7418.342}
        x2={-2203.452}
        y2={7418.342}
        gradientTransform="matrix(1 0 0 -1 2510.87 7724.265)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0b0d0d",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#020203",
          }}
        />
      </linearGradient>
      <path
        style={{
          opacity: 0.15,
          fill: "url(#SVGID_00000126322287376476644270000007800529574446808487_)",
          enableBackground: "new",
        }}
        d="M228.96 307.33h78.45l-2.8-2.81-75.65 2.81z"
      />
      <linearGradient
        id="SVGID_00000181089072187582210170000013674819156511349171_"
        gradientUnits="userSpaceOnUse"
        x1={-2232.642}
        y1={7366.574}
        x2={-2150.173}
        y2={7366.574}
        gradientTransform="matrix(1 0 0 -1 2510.87 7724.265)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0b0d0d",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#020203",
          }}
        />
      </linearGradient>
      <path
        style={{
          opacity: 0.15,
          fill: "url(#SVGID_00000181089072187582210170000013674819156511349171_)",
          enableBackground: "new",
        }}
        d="m278.23 356.65 78.94-.73 3.53 3.54-82.47-2.81z"
      />
      <path className="st1" d="M315.57 307.32H156.96l-.12 50.31 200.33-1.71" />
      <linearGradient
        id="SVGID_00000088824368869805581890000011766040925763351983_"
        gradientUnits="userSpaceOnUse"
        x1={-2354.034}
        y1={7364.74}
        x2={-2279.229}
        y2={7364.74}
        gradientTransform="matrix(1 0 0 -1 2510.87 7724.265)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#0b0d0d",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#020203",
          }}
        />
      </linearGradient>
      <path
        style={{
          opacity: 0.15,
          fill: "url(#SVGID_00000088824368869805581890000011766040925763351983_)",
          enableBackground: "new",
        }}
        d="m156.84 357.63 69.82-.61 4.99 5.01-74.81-4.4z"
      />
    </svg>
  </SvgIcon>
);

export default RatherMember;
