import React from "react";

const WikiLogoLight = ({ ...props }) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 434.7 109.74"
    style={{
      enableBackground: "new 0 0 434.7 109.74",
    }}
    xmlSpace="preserve"
    width="10em"
    height="3em"
    {...props}
  >
    <style>{".st0{opacity:.15;enable-background:new}"}</style>
    <path d="M50.73 46.76H36.26v4.09h14.21c2.29 0 4.76.8 4.76 4.02 0 3.11-1.82 3.94-5.19 3.94h-1.59v4.09h2.35c5.85 0 8.37-4.05 8.37-8.03-.01-4.21-2.09-8.11-8.44-8.11z" />
    <path d="M36.26 46.76v6.18l16.7 16.73h6.57L36.26 46.76z" />
    <path className="st0" d="m36.26 46.76 4.15 4.09h.41l-4.56-4.09z" />
    <path d="M48.72 69.67h-5.84l-6.63-6.77.01-4.09h1.64l10.82 10.86z" />
    <path
      className="st0"
      d="M42.11 58.81h6.38l-.23-.23-6.15.23zM46.12 62.82l6.42-.06.29.29-6.71-.23z"
    />
    <path d="M49.15 58.81h-12.9l-.01 4.09 16.29-.14" />
    <path className="st0" d="m36.25 62.9 5.68-.05.41.41-6.09-.36z" />
    <path d="M148.97 72.26V43.25h12.44c3.23 0 5.8.86 7.71 2.57 1.91 1.71 2.86 3.9 2.86 6.55 0 2.18-.67 4.05-2.01 5.6-1.34 1.55-3.23 2.58-5.66 3.11l7.46 11.19h-4.35l-7.05-10.78h-7.67v10.78h-3.73zm3.73-14.3h8.71c2.24 0 3.94-.5 5.1-1.51 1.16-1.01 1.74-2.37 1.74-4.08s-.58-3.07-1.74-4.08c-1.16-1.01-2.86-1.51-5.1-1.51h-8.71v11.18zM175.79 72.26l7.46-29.02h11.4l7.46 29.02h-3.73l-2.28-8.5h-14.3l-2.28 8.5h-3.73zm6.84-12.02h12.64l-3.52-13.47h-5.6l-3.52 13.47zM202.61 46.77v-3.52h23.63v3.52h-9.95v25.5h-3.73v-25.5h-9.95zM230.88 72.26V43.25h3.73v12.64h15.55V43.25h3.73v29.02h-3.73V59.41h-15.55v12.85h-3.73zM261.43 72.26V43.25h20.31v3.52h-16.58v9.12h13.47v3.52h-13.47v9.33h16.58v3.52h-20.31zM287.63 72.26V43.25h12.44c3.23 0 5.8.86 7.71 2.57 1.91 1.71 2.86 3.9 2.86 6.55 0 2.18-.67 4.05-2.01 5.6-1.34 1.55-3.23 2.58-5.66 3.11l7.46 11.19h-4.35l-7.05-10.78h-7.67v10.78h-3.73zm3.73-14.3h8.71c2.24 0 3.94-.5 5.1-1.51 1.16-1.01 1.74-2.37 1.74-4.08s-.58-3.07-1.74-4.08c-1.16-1.01-2.86-1.51-5.1-1.51h-8.71v11.18zM314.25 43.25h7.67l3.73 20.31h.41l3.9-20.31h9.2l3.9 20.31h.42l3.73-20.31h7.67l-6.01 29.02H338.5l-3.73-19.48h-.41l-3.73 19.48h-10.36l-6.02-29.02zM359.1 72.26V43.25h7.67v29.02h-7.67zM373.07 72.26V43.25h7.67v10.78h3.32l7.05-10.78h8.71l-9.12 13.68 9.74 15.34h-8.71l-7.25-11.19h-3.73v11.19h-7.68zM404.04 72.26V43.25h7.67v29.02h-7.67zM81.26 46.88l5.62-.73 2.93 15.97h.31l3.21-16.71 6.18-.82 3.36 17.53h.31l3.42-18.63 6.01-.87-4.98 24.02-8.37 1.08-2.62-13.69h-.31l-2.8 14.62-7.78 1.02-4.49-22.79z" />
    <path d="m121.46 26.48-49.34 6.78H22.99v49.92h48.55v.09l.66-.09h57.81v-50.2l-8.55-6.5zm7.31 7.1v47.71l-7.31-5.72V28.03l7.31 5.55zm-1.17 48.36H81.21l39.46-5.42 6.93 5.42zm-7.38-6.62-47.31 6.5V34.4l47.31-6.5v47.42zm-95.99 6.62V34.49h47.31v47.44H24.23z" />
  </svg>
);

export default WikiLogoLight;
