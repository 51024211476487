import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  childrenFriendly: ({ noPadding }) => ({
    justifyContent: "left",
    textAlign: "left",
    height: "max-content",
    textTransform: "unset",
    minWidth: "unset",
    padding: noPadding ? 0 : undefined,
    "&:hover": {
      opacity: 0.9,
    },
  }),
  label: {
    display: "block",
  },
});

const ChildrenFriendlyButton = ({ children, noPadding, ...rest }) => {
  const classes = useStyles({ noPadding });
  return (
    <Button
      classes={{
        root: classes.childrenFriendly,
        label: classes.label,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ChildrenFriendlyButton;
