import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AlertContainer from "components/AlertContainer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSession } from "store/Session";
import SelectImage from "components/SelectImage";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";
import Edit from "@material-ui/icons/Edit";
import useHit from "hooks/useHit";
import APIEndpoints from "APIEndpoints";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "i18n";
import DialogForm from "./DialogForm";

const useStyles = makeStyles({
  inputs: {
    display: "flex",
    marginBottom: 8,
    alignItems: "center",
    "& > :first-child": {
      marginRight: 16,
    },
  },
});

const validationSchema = yup.object({
  avatar: yup.string().required(),
  username: yup
    .string()
    .matches(/^[a-z0-9]+$/i, "Solo letras y números")
    .min(3, "Mínimo 3 caracteres")
    .max(30, "Máximo 30 caracteres")
    .required("Es necesario un username"),
});

const EditUserDialog = ({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  children,
  cash,
  ...rest
}) => {
  const classes = useStyles();
  const alertContainer = useRef();
  const [loadingImage, setLoadingImage] = useState(false);
  const { data: session, refresh } = useSession();
  const hit = useHit();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      avatar: session.user.avatar,
      username: session.user.username,
    },
    validationSchema,
    onSubmit: async (data) => {
      const { error } = await hit(APIEndpoints.USER.UPDATE, data);
      if (error) {
        alertContainer.current.setAlert({ variant: "error", text: t("USERNAME_ALREADY_USED") });
      } else {
        refresh();
        alertContainer.current.setAlert({ color: "success", text: t("PROFILE_UPDATED_SUCCESSFULLY") });
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    },
  });

  const onSelect = (assetUrl) => {
    setLoadingImage(false);
    formik.setFieldValue("avatar", assetUrl);
  };

  return (
    <DialogForm
      cross
      open
      onClose={onClose}
      title={t("EDIT_PROFILE")}
      fullScreen={false}
      actions={(
        <>
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            variant="contained"
            color="primary"
          >
            {t("SAVE")}
          </Button>
        </>
      )}
      {...rest}
    >
      <Box className={classes.inputs}>
        <SelectImage onLoading={() => setLoadingImage(true)} onChange={onSelect}>
          <ChildrenFriendlyButton>
            {
              loadingImage
                ? <CircularProgress />
                : (
                  <Badge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    color="primary"
                    badgeContent={<Edit fontSize="small" />}
                  >
                    <Avatar
                      style={{
                        width: 70,
                        height: 70,
                      }}
                      src={formik.values.avatar}
                    />
                  </Badge>
                )
            }
          </ChildrenFriendlyButton>
        </SelectImage>
        <TextField
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && !!formik.errors.username}
          helperText={formik.touched.username && formik.errors.username}
          label="Username"
        />
      </Box>
      <AlertContainer ref={alertContainer} />
    </DialogForm>
  );
};

export default EditUserDialog;
