import React from "react";
import Button from "@material-ui/core/Button";
import { Divider, Typography } from "@material-ui/core";
import { useTranslation } from "i18n";
import DialogForm from "./DialogForm";

const RulesAndGuidelinesDialog = ({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  children,
  callback,
  cash,
  ...rest
}) => {
  const { t } = useTranslation();
  return (

    <DialogForm
      cross
      open
      onClose={onClose}
      title={t("RULES_AND_GUIDELINES")}
      fullScreen={false}
      actions={(
        <>
          <Button onClick={onClose}>
            Ok
          </Button>
        </>
      )}
      {...rest}
    >
      <Typography color="textSecondary" variant="h6">
        {t("RULE_1_TITLE")}
      </Typography>
      <Typography variant="body2">
        {t("RULE_1_TEXT")}
      </Typography>
      <Divider variant="inset" />
      <Typography color="textSecondary" variant="h6">
        {t("RULE_2_TITLE")}
      </Typography>
      <Typography variant="body2">
        {t("RULE_2_TEXT")}

      </Typography>
      <Divider variant="inset" />
      <Typography color="textSecondary" variant="h6">
        {t("RULE_3_TITLE")}
      </Typography>
      <Typography variant="body2">
        {t("RULE_3_TEXT")}
      </Typography>
    </DialogForm>
  );
};

export default RulesAndGuidelinesDialog;
